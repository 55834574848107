import { useEffect } from 'react';

import './style.css';
import 'vanilla-cookieconsent/dist/cookieconsent.js';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import type { CookiesCookieGroup, CookiesCookiesConsent } from '@eva/cms-types';

interface Props {
  locale: string;
  data: CookiesCookiesConsent['attributes'];
}

function CookieConsent(props: Props) {
  const {
    group,
    settingsSave,
    settingsTitle,
    settingsClose,
    consentSettings,
    consentAcceptAll,
    settingsAcceptAll,
    settingsRejectAll,
    consentDescription,
  } = props.data;

  const blocks = group as unknown as CookiesCookieGroup[];

  const languages = {
    [props.locale]: {
      consent_modal: {
        description: consentDescription,
        primary_btn: {
          text: consentAcceptAll,
          role: 'accept_all',
        },
        secondary_btn: {
          text: consentSettings,
          role: 'settings',
        },
      },
      settings_modal: {
        title: settingsTitle,
        save_settings_btn: settingsSave,
        accept_all_btn: settingsAcceptAll,
        reject_all_btn: settingsRejectAll,
        close_btn_label: settingsClose,
        cookie_table_headers: [{ col1: 'Name' }],
        blocks: blocks.map((block) => {
          const data = block as unknown as CookiesCookieGroup['attributes'];
          return {
            title: data.Title,
            description: data.description,
            toggle: {
              value: data.value
                ?.toString()
                .normalize('NFKD')
                .replace(/[^\w]/g, ''),
              enabled: data.enabled,
              readonly: data.readonly,
              autoClear: {
                cookies: data.cookies,
              },
            },
            cookie_table: (data.cookies as unknown as { name: string }[])?.map(
              (cookie: { name: string }) => ({
                col1: cookie.name,
              }),
            ),
          };
        }),
      },
    },
  };

  const cookieConsentConfig = {
    gui_options: {
      consent_modal: {
        layout: 'box',
        position: 'bottom right',
        flip_buttons: true,
        equal_weight_buttons: true,
      },
      preferences_modal: {
        layout: 'box',
        position: 'left right',
        flipButtons: false,
        equal_weight_buttons: true,
      },
    },
    autoclear_cookies: true,
    page_scripts: true,
    current_lang: props.locale,
    languages: languages,
  };

  useEffect(() => {
    // @ts-ignore
    const cookieConsent = initCookieConsent();

    cookieConsent.run(cookieConsentConfig);
  }, []);

  return null;
}

export default CookieConsent;
